/* Add this line to your CSS or SCSS file */
@import "~bootstrap-icons/font/bootstrap-icons.css";

.logo-2 {
  background-image: url("./assets/Logo.png");
}
.font-sm {
  font-size: 0.8rem;
  font-weight: 600;
}
.font-md {
  font-size: 1rem;
  font-weight: 600;
}
.font-lg {
  font-size: 1.5rem;
  font-weight: 600;
}
.text-red {
  color: red;
}

.text-merun {
  background-color: #941331 !important;
}

#myDataTable {
  font-size: 0.8rem;
  font-weight: 600;
}

/*============ BUTTONS ===============*/

.btn-custom-primary {
  color: #000;
  font-weight: 600;
  background-color: #B2BCC9;
  border-color: #B2BCC9;
}

.btn-custom-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6D717B;
  --bs-btn-border-color: #6D717B;

  --bs-btn-hover-color: #636363;
  --bs-btn-hover-bg: var(--secondaryy-color);
  --bs-btn-hover-border-color: var(--secondaryy-color);

  --bs-btn-focus-shadow-rgb: 49, 132, 253;

  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--cta-btn);
  --bs-btn-active-border-color: var(--cta-btn);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}


.bg-custom {
  background-color: #B2BCC9;
}
.bg-custom-2 {
  background-color: #ACAC9F;
}
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

/*============== CUSTOM FONTS =================*/

.arabic-font-1 {
  font-family: 'Arabic Typesetting';
  font-weight: 600;
}

.arabic-font-2 {
  font-family: 'Andalus';
  font-weight: 600;
}

.arabic-font-3 {
  font-family: 'Aref Ruqaa Ink';
  font-weight: 600;
}

.urdu-font-1 {
  font-family: 'Urdu Typesetting';
  font-weight: 600;
}



.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 200px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.audio-center {
  text-align: center;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--qc-grey);
  padding-top: 10px
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .audio-list {
    height: 600px;
  }
}
